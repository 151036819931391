@charset "utf-8";

@import '../mixin';
@import '../variable';

.office-section {
    background-color: #ADADAD;
    padding: 75px 3% 0;
    position: relative;
    @media screen and (max-width: $lg) {
        padding: 60px 3% 0;
    }
    @media screen and (max-width: $sm) {
        padding: 10px 3% 0;
    }
    #access {
        position: absolute;
        top: 0;
        margin-top: -90px;
        @media screen and (max-width: $sm) {
            margin-top: -60px;
        }
    }
    .title-section {
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translate(0, -50%) rotate(90deg);
        font-family: $font-secondary;
        font-size: 28px;
        color: $color-white;
        font-weight: normal;
        @media screen and (max-width: 1300px) {
            font-size: 24px;
        }
        @media screen and (max-width: $lg) {
            position: relative;
            transform: unset;
            left: auto;
            top: auto;
            text-align: center;
            font-size: 16px;
            margin-bottom: 10px;
        }
        @media screen and (max-width: $sm) {
            font-size: 12px;
            margin-bottom: 30px;
        }
    }
    .inner {
        max-width: 1110px;
        width: 100%;
        margin: 0 auto 65px;
        @media screen and (max-width: $sm) {
            margin: 0 auto 45px;
        }
        h2 {
            text-align: center;
            font-size: 28px;
            line-height: 2;
            margin-bottom: 60px;
            color: #FFF;
            @media screen and (max-width: $lg) {
                font-size: 24px;
                line-height: 1.5;
                margin-bottom: 40px;
            }
            @media screen and (max-width: $sm) {
                font-size: 16px;
                margin-bottom: 25px;
            }
        }
        .listOffice {
            display: flex;
            justify-content: space-evenly;
            @media screen and (max-width: 1300px) {
                justify-content: space-around;
            }
            @media screen and (max-width: $sm) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            li {
                width: 27.5%;
                text-align: center;
                color: #FFF;
                font-family: $font-secondary;
                font-weight: normal;
                @media screen and (max-width: $xl) {
                    width: 31.5%;
                }
                @media screen and (max-width: $sm) {
                    max-width: 275px;
                    width: 100%;
                    margin: 0 auto 35px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                h3 {
                    font-size: 24px;
                    margin-bottom: 25px;
                    @media screen and (max-width: $lg) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: $sm) {
                        font-size: 26px;
                        margin-bottom: 15px;
                    }
                }
                img {
                    max-width: 270px;
                    width: 100%;
                    margin: 0 auto 25px;
                    display: block;
                    @media screen and (max-width: $sm) {
                        margin: 0 auto 15px;
                    }
                }
                .tel {
                    display: block;
                    font-size: 20px;
                    margin-bottom: 20px;
                    @media screen and (max-width: $lg) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: $sm) {
                        font-size: 20px;
                        margin-bottom: 15px;
                    }
                }
                .ctnBtn {
                    max-width: 230px;
                    width: 100%;
                    height: 35px;
                    margin: 0 auto 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    border: 1px solid $color-white;
                    font-size: 14px;
                    border-radius: 3px;
                    position: relative;
                    transition: $animate;
                    @media screen and (max-width: $sm) {
                        max-width: 216px;
                        height: 33px;
                        font-size: 15px;
                        margin-bottom: 15px;
                    }
                    &:hover {
                        background-color: #FFF;
                        color: #ADADAD;
                    }
                }
                .addressBox {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 500;
                    @media screen and (max-width: $lg) {
                        flex-direction: column-reverse;
                        align-items: center;
                        justify-content: center;
                    }
                    .address {
                        flex: 1;
                        padding-right: 10px;
                        font-size: 15px;
                        line-height: 1.75;
                        @media screen and (max-width: $lg) {
                            font-size: 13px;
                        }
                        @media screen and (max-width: $sm) {
                            font-size: 14px;
                            letter-spacing: 1px;
                        }
                    }
                    a {
                        width: 33px;
                        height: 16px;
                        background-color: transparent;
                        border: 1px solid $color-white;
                        border-radius: 3px;
                        font-size: 11px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        top: 3px;
                        letter-spacing: 0;
                        position: relative;
                        transition: $animate;
                        &:hover {
                            background-color: #FFF;
                            color: #ADADAD;
                        }
                        @media screen and (max-width: $lg) {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
    .sns {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 45px;
        position: relative;
        @media screen and (max-width: $sm) {
            padding-bottom: 25px;
        }
        &:before {
            position: absolute;
            content: "";
            bottom: 0;
            width: 1px;
            height: 35px;
            background-color: $color-white;
            @media screen and (max-width: $sm) {
                height: 18px;
            }
        }
        li {
            margin: 0 9px;
            @media screen and (max-width: $sm) {
                margin: 0 7.5px;
            }
            a {
                position: relative;
                bottom: 0;
                transition: $animate;
                &:hover {
                    bottom: 5px;
                }
                img {
                    width: 30px;
                    @media screen and (max-width: $sm) {
                        width: 26px;
                    }
                }
            }
        }
    }
}