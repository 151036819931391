@charset "utf-8";

@import 'mixin';
@import 'variable';

* {
	-webkit-tap-highlight-color: transparent;
	box-sizing: border-box;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1.5;
}
ul, ol, li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
img {
	max-width: 100%;
	vertical-align: bottom;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.h1, .h2, .h3, h1, h2, h3 {
	margin: 0;
	padding: 0;
}
.h4, .h5, .h6, h4, h5, h6 {
	margin: 0;
	padding: 0;
}
a {
	color: inherit;
	text-decoration: none;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}
.bold {
	font-weight: bold;
}
.bg-set {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.hvO {
	@include transition(0.15s ease opacity);
	&:hover {
		@include opacity(0.8);
	}
}
.hvU {
	&:hover {
		text-decoration: underline;
	}
}

.hvUa {
	position: relative;
	
	&:before {
		display: block;
		content: "";
		width: 0%;
		height: 1px;
		background-color: $color-button;
		position: absolute;
		left: 0;
		bottom: -2px;
		z-index: 2;
		transition: width 500ms cubic-bezier(0.23, 1, 0.32, 1);
		@media screen and (max-width: $md) {
			display: none;
		}
	}
	&:hover {
		&:before {
			width: 100%;
		}
	}
}

strong,
b {
    font-weight: bold;
}

.cont {
	max-width: 1114px;
	width: 100%;
	margin: auto;
	@media screen and (min-width: $xxl) {
		max-width: 100%;
		padding: 0 7.5%;
	}
	@media screen and (max-width: $lg) {
		max-width: 100%;
		padding: 0 3%;
	}
	@media screen and (max-width: $sm) {
		max-width: 100%;
		padding: 0 5%;
	}
}

.only-mobile {
    display: none;
    @media screen and (max-width: $sm) {
        display: block;
    }
}

.only-desktop {
    @media screen and (max-width: $md) {
        display: none;
    }
}

.only-tab {
	display: none;
	@media screen and (max-width: $md) {
        display: block;
    }
}

.boxContainer {
	max-width: 900px;
	width: 100%;
	margin: auto;
	@media screen and (max-width: $md) {
		padding: 0 10%;
	}
}

.noOverflow {
    overflow: hidden;
}