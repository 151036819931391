@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';

::selection {
    background-color: mix($color-brand, white, 80%);
    color: #fff;
}

body {
	margin: 0;
    padding: 0;
    font-family: $font-base;
	color: $color-base;
	@include box-sizing(border-box);
    overflow-x: hidden;
    font-size: 16px;
    position: relative;
    box-sizing: border-box;
    letter-spacing: 1px;
    font-weight: 500;
    font-feature-settings: "palt";
    letter-spacing: 2px;
}

.main-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    background-color: $color-third;
    color: #fff;
    font-family: $font-secondary;
    font-weight: bold;
    transition: all .2s ease-out;

    svg {
        margin-left: 15px;
        width: 32px;
        height: 6px;

        @media screen and (max-width: $sm) {
            width: 18px;
            height: 4px;
        }
    }

    &:hover {
        color: #fff;
        background-color: #4d4d4d;
    }
}

.main-button2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    background-color: #A38A77;
    color: #fff;
    font-family: $font-secondary;
    font-weight: bold;
    transition: all .2s ease-out;

    svg {
        margin-left: 15px;
        width: 32px;
        height: 6px;

        @media screen and (max-width: $sm) {
            width: 18px;
            height: 4px;
        }
    }

    &:hover {
        color: #fff;
        background-color: #745944;
    }
}

@import 'component/header.scss';

@import 'component/footer.scss';

@import 'component/mainvisual.scss';

@import 'component/breadcrumb.scss';

@import 'component/cookie.scss';

@import 'component/office.scss';

@import 'component/kaname-group.scss';



