@charset "utf-8";

@import '../mixin';
@import '../variable';

/* SASS UNTUK BREADCRUMB */

/*-----BREADCRUMB-----*/
.breadcrumbPage {
    width: 100%;
    z-index: 1;
    overflow: hidden;
    background-color: white;
    ul {
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: $sm) {
            flex-wrap: wrap;
            padding: 10px 0;
        }
        li {
            font-size: 14px;
            letter-spacing: 1px;
            color: $color-base;
            font-weight: bold;
            @media screen and (max-width: $sm) {
                font-size: 12px;
            }
            a { 
                position: relative;
                color: $color-brand;
            }
            span {
                display: inline-block;
                margin: 0 8px 0 13px;
                @media screen and (max-width: $sm) {
                    margin: 0 3px 0 8px;
                }
            }
            &.active {
                color: $color-base;
            }
        }
    }
    
}