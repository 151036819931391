@charset "utf-8";

@import '../mixin';
@import '../variable';

.kanameGroup {
    padding: 85px 3% 75px;
    @media screen and (max-width: 1300px) {
        padding: 70px 3%;
    }
    @media screen and (max-width: $lg) {
        padding: 60px 3%;
    }
    @media screen and (max-width: $md) {
        padding: 40px 3% 20px;
    }
    @media screen and (max-width: $sm) {
        padding: 50px 3% 40px;
    }
    .inner {
        max-width: 1237px;
        width: 100%;
        margin: auto;
        text-align: center;
        .headingKanameGroup {
            font-family: $font-secondary;
            font-weight: normal;
            margin-bottom: 40px;
            h2 {
                font-size: 36px;
                @media screen and (max-width: $lg) {
                    font-size: 32px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 22px;
                }
            }
            p {
                color: #939393;
                font-size: 14px;
                @media screen and (max-width: $lg) {
                    font-size: 12px;
                }
                @media screen and (max-width: $sm) {
                    font-size: 14px;
                }
            }
        }
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: $lg) {
                flex-direction: column;
                align-items: center;
            }
            li {
                text-align: center;
                @media screen and (max-width: $lg) {
                    margin-bottom: 30px;
                }
                a {
                    img {
                        margin-bottom: 10px;
                    }
                    h3 {
                        text-align: center;
                        font-size: 19px;
                        font-weight: bold;
                        @media screen and (max-width: $sm) {
                            font-size: 13px;
                        }
                    }
                }
                &.kaname {
                    a {
                        img {
                            width: 215px;
                            @media screen and (max-width: $sm) {
                                width: 152px;
                            }
                        }
                        h3 {
                            color: $color-brand;
                        }
                    }
                }
                &.estate {
                    a {
                        img {
                            width: 263px;
                            @media screen and (max-width: $sm) {
                                width: 186px;
                            }
                        }
                        h3 {
                            color: #6C462B;
                        }
                    }
                }
                &.andaqua {
                    a {
                        img {
                            width: 197px;
                            @media screen and (max-width: $sm) {
                                width: 139px;
                            }
                        }
                        h3 {
                            color: #007DD7;
                        }
                    }
                }
                &.red {
                    a {
                        img {
                            width: 270px;
                            margin-bottom: 0;
                            @media screen and (max-width: $sm) {
                                width: 191px;
                            }
                        }
                    }
                }
            }
        }
    }
}