@charset "utf-8";

@import '../mixin';
@import '../variable';

/* SASS UNTUK FOOTER */

.cr {
    width: 100%;
    text-align: center;
    padding: 25px 3%;
    font-size: 14px;
    @media screen and (max-width: $lg) {
        padding: 15px 3%;
        font-size: 12px;
    }
    @media screen and (max-width: $sm) {
        padding: 15px 3% 65px;
    }
}

.pageTop {
    position: fixed;
    right: -100px;
    bottom: 110px;
    width: 65px;
    height: 65px;
    background: rgba(255,255,255,0.75);
    border: 1px solid #333333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    cursor: pointer;
    transition: $animate;
    @media screen and (max-width: $lg) {
        bottom: 40px;
    }
    @media screen and (max-width: $sm) {
        width: 40px;
        height: 40px;
        bottom: 55px;
    }
    &.active {
        right: 50px;
        @media screen and (max-width: $lg) {
            right: 30px;
        }
        @media screen and (max-width: $sm) {
            right: 15px;
        }
    }
    &:hover {
        bottom: 115px;
        @media screen and (max-width: $lg) {
            bottom: 45px;
        }
        @media screen and (max-width: $sm) {
            bottom: 60px;
        }
    }
    svg {
        width: 19px;
        @media screen and (max-width: $sm) {
            width: 13px;
        }
    }
}

.float-btn {
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: #666666;
    left: 0;
    bottom: 0;
    z-index: 5;
    color: #FFF;
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: space-between;
    }
    div {
        width: 50%;
        &:last-child {
            border-left: 1px solid #F5F5F5;
        }
        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $animate;
            i {
                margin-right: 10px;
            }
            span {
                font-weight: bold;
                font-size: 14px;
                letter-spacing: 1px;
            }
        }
        &:first-child {
            a {
                i {
                    position: relative;
                    transform: scaleX(-1);
                }
            }
        }
    }
}