@charset "utf-8";

@import '../mixin';
@import '../variable';

/* SASS UNTUK MAINVISUAL */


#mainvisual {
    margin-top: 0;
    position: relative;

    &.topPage {
        width: 100%;
        height: 98vh;
        @media screen and (max-width: $sm) {
            height: auto;
            margin-top: 60px;
        }
        .bg {
            width: 100%;
            height: 100%;
            overflow: hidden;
            // background-image: url(../img/mainvisual/top-mv.jpg);
            position: relative;
            @media screen and (max-width: $sm) {
                height: 56.5vw;
            }
            video {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                min-width: 100%;
                min-height: 100%;
                z-index: 1;
                @media screen and (max-width: $sm) {
                    position: relative;
                    left: auto;
                    top: auto;
                    transform: unset;
                    min-width: inherit;
                    min-height: inherit;
                    width: 100%;
                    height: auto;
                }
                // @media screen and (max-width: $sm) {
                //     display: none;
                // }
            }
        }
        .scroll-down {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            z-index: 1;
            transform: rotate(90deg);
            @media screen and (max-width: $sm) {
                display: none;
            }
            p {
                top: -40px;
                padding-bottom: 10px;
                border-bottom: 1px solid #FFF;
                padding: 0px 16px 10px;
                font-size: 13px;
                position: relative;
            }
        }
    }

    &.subPage {
        padding: 96px 0 78px;
        position: relative;
        @media screen and (max-width: $md) {
            padding: 70px 0 50px;
        }
        @media screen and (max-width: $sm) {
            padding: 60px 16.3% 40px;
        }
        .inner {
            position: relative;
            max-width: 465px;
            width: 100%;
            margin: auto;
            .text {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                text-align: center;
                color: #ACACAC;
                h3 {
                    font-size: 19px;
                    padding-bottom: 10px;
                    position: relative;
                    margin-bottom: 10px;
                    @media screen and (max-width: $sm) {
                        font-size: 2.7vw;
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%, 0);
                        width: 30px;
                        height: 2px;
                        background-color: #ACACAC;
                        @media screen and (max-width: $sm) {
                            font-size: 15px;
                        }
                    }
                }
                h1 {
                    font-size: 22px;
                    @media screen and (max-width: $sm) {
                        font-size: 3.5vw;
                    }
                }
            }
        }
    }
}