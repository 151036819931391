@charset "utf-8";

@import '../mixin';
@import '../variable';

.fixedRight {
    position: fixed;
    right: 0;
    top: 45vh;
    background-color: #FFFFFF;
    border: 1px solid #EEE;
    z-index: 21;
    @media screen and (max-width: $lg) {
        top: 50vh;
    }
    // @media screen and (max-width: $md) {
    //     display: none;
    // }
    .contact {
        width: 50px;
        border-radius: 8px 0 0 8px;
        padding: 7px 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: $animate;
        top: 0;
        &:hover {
            top: 5px;
        }
        .chicken {
            position: absolute;
            left: -4px;
            top: -25px;
        }
        span {
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            color: $color-hover;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 17px;
        }
        &.pading {
            padding: 0px 10px 7px;
        }
        &.padingTop {
            padding: 10px 10px 7px;
        }
    }
}

.headerBlock {
    margin-top: 90px;
    @media screen and (max-width: $sm) {
        margin-top: 60px;
    }
}

/*HEADER*/
#header {
    width: 100%;
    height: 90px;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: $color-white;
    position: fixed;
    box-shadow: 0px 0px 6px 0px rgba(16,16,16,0.15);
    transition: $animate;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    @media screen and (max-width: $sm) {
        height: 60px;
        padding-left: 15px;
    }
    .leftSide {
        width: 180px;
        @media screen and (max-width: $sm) {
            width: 133px;
        }
    }
    .rightSide {
        flex: 1;
        padding-left: 15px;
        padding-right: 128px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media screen and (max-width: $xl) {
            padding-right: 115px;
        }
        @media screen and (max-width: $sm) {
            padding-right: 68px;
        }
        nav {
            @media screen and (max-width: $lg) {
                display: none;
            }
            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                li {
                    margin-left: 64px;
                    position: relative;
                    @media screen and (max-width: $xl) {
                        margin-left: 25px;
                    }
                    @media screen and (max-width: 1250px) {
                        margin-left: 20px;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        font-size: 17px;
                        @media screen and (max-width: $xl) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 1250px) {
                            font-size: 15px;
                        }
                    }
                    ul {
                        display: block;
                        position: absolute;
                        overflow: hidden;
                        opacity: 0;
                        transform: translateY(-8px);
                        visibility: hidden;
                        left: 0;
                        transition: all .4s ease-in-out, visibility 0s .3s;
                        width: 100%;
                        background-color: #FFF;
                        li {
                            margin-left: 0;
                            border-bottom: 1px solid #CDCDCD;
                            &:first-child {
                                border-top: 1px solid #cdcdcd;
                            }
                            a {
                                display: block;
                                font-size: 15px;
                                padding: 8px 12px;
                                transition: $animate;
                                &:hover {
                                    background-color: #4d4d4d;
                                    color: #FFF;
                                }
                            }
                        }
                    }
                    &:hover {
                        ul {
                            opacity: 1;
                            visibility: visible;
                            transform: translateY(0);
                            transition: all .4s ease-in-out, visibility 0s;
                        }
                    }
                }
            }
        }
        .ctnBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 100px;
            @media screen and (max-width: $xl) {
                margin-left: 50px;
            }
            @media screen and (max-width: 1250px) {
                margin-left: 15px;
            }
            @media screen and (max-width: $lg) {
                margin-left: 0;
            }
            div {
                width: 286px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 7.5px;
                @media screen and (max-width: $sm) {
                    width: 94px;
                    margin-bottom: 0;
                }
                a {
                    width: 138px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-third;
                    color: #FFF;
                    position: relative;
                    transition: $animate;
                    @media screen and (max-width: $sm) {
                        height: 45px;
                        width: 45px;
                        text-align: center;
                        font-size: 14px;
                        line-height: 1.25;
                    }
                    &:hover {
                        background-color: $color-hover;
                    }
                }
                &.btnDesktop {
                    @media screen and (max-width: $sm) {
                        display: none;
                    }
                }
                &.btnMobile {
                    display: none;
                    @media screen and (max-width: $sm) {
                        display: flex;
                    }
                }
            }
            p {
                font-size: 14px;
                line-height: 1;
                @media screen and (max-width: $sm) {
                    display: none;
                }
            }
        }
    }
}

.headerSpBtn {
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    width: 90px;
    height: 90px;
    background-color: $color-third;
    border: none;
    text-align: center;
    outline: none;
    border-radius: 0;
    transition: all .2s ease-in-out;
    z-index: 22;
    @media screen and (max-width: $sm) {
        width: 60px;
        height: 60px;
    }
    
    > div {
        position: absolute;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 1px;
        background-color: $color-white;
        width: 35px;
        transition: all .2s ease-in-out;
        @media screen and (max-width: $sm) {
            width: 24px;
        }

        &::after,
        &::before {
            position: absolute;
            display: inline-block;
            content: '';
            height: 1px;
            width: 100%;
            background-color: $color-white;
            transform-origin: center;
            transition: all .2s ease-in-out;
        }

        &::after {
            bottom: -12px;
            left: 0;
            @media screen and (max-width: $sm) {
                bottom: -10px;
            }
        }

        &::before {
            top: -12px;
            left: 0;
            @media screen and (max-width: $sm) {
                top: -10px;
            }
        }
    }

    &._active {

        > div {
            background-color: transparent;

            &::after {
                transform: rotate(45deg);
                bottom: 0;
            }

            &::before {
                transform: rotate(-45deg);
                top: 0;
            }
        }
    }

}

/* NAV SP */
.navSp::-webkit-scrollbar {
    width: 0.5em;
}

.navSp {
    position: fixed;
    top: 0;
    right: -100%;
    width: 445px;
    height: 100%;
    background-color: #666666;
    z-index: 21;   
    overflow-y: auto;
    opacity: 1;
    visibility: hidden;
    transition: $animate;
    padding: 70px;
    font-family: $font-secondary;
    font-size: 14px;
    color: #FFF;
    padding-bottom: 30px;
    &._active {
        right: 0;
        visibility: visible;
    }
    @media screen and (max-width: $sm) {
        width: 100%;
        padding: 40px;
        padding-bottom: 15px;
    }

    .inner {
        .sns {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            a {
                margin-right: 17px;
                width: 30px;
                &:last-child {
                    margin-right: 0;
                }
                img {
                    width: 100%;
                }
            }
        }
        .list-nav {
            margin-bottom: 30px;
            li {
                border-bottom: 1px solid #FFF;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 14.5px 0;
                    font-family: $font-secondary;
                    font-size: 14px;
                    font-weight: normal;
                    position: relative;
                    transition: $animate;
                    left: 0;
                    &:hover {
                        left: 5px;
                    }
                }
                &:first-child {
                    a {
                        padding-top: 0;
                    }
                }
                .dropdownSp {
                    display: none;
                    width: 100%;
                }
                &.hasChild {
                    .menuSp {
                        display: flex;
                        justify-content: space-between;
                        i {
                            font-size: 16px;
                            transition: $animate;
                            color: $color-gray;
                        }
                        &.nav-change {
                            i {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    .dropdownSp {
                        width: 100%;
                        padding-bottom: 10px;
                        margin-top: -10px;
                        li {
                            border-bottom: 0;
                            a {
                                font-size: 12px;
                                font-weight: 500;
                                font-family: $font-base;
                                padding: 4px 0;
                            }
                        }
                    }
                }
                &.last {
                    margin-top: 35px;
                    border-bottom: 0;
                    a {
                        padding: 0;
                        font-family: $font-base;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}