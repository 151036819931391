@charset "utf-8";

@import '../mixin';
@import '../variable';

.cc-window {
    @include box-shadow(0px 0px 16px 0px rgba(0, 0, 0, 0.08) !important); 
    @include border-radius(5px);
}

.cc-color-override--1821647713.cc-window {
    background-color: #fff !important;
}

.cc-message a {
    color: $color-link !important;
    position: relative;
    text-decoration: none !important;
    font-weight: bold;
    &:before {
        content: "";
        position: absolute;
        bottom: 3px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        background: $color-link;
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform 0.3s;
    }
    &:hover {
        &:before {
            transform: scale(1, 1);
        }
    }
}

.cc-btn {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    font-size: 18px;
    font-weight: bold;
    width: 318px;
    height: 50px;
    color: #FFF !important;
    background-color: $color-button !important;
    @include transition(all .5s);
    border: 1px solid $color-button !important;
    margin: 0 8px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    padding: 0 !important;
    font-family: $font-base !important;
    &:hover {
        background-color: $color-hover !important;
        border-color: $color-hover !important;
    }
}

.cc-revoke, .cc-window {
    font-family: $font-base !important;
    font-weight: 500;
}